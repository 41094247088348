import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBGb-HrN_lfkxwBMHujYExDOfdGplPzddY",
  authDomain: "acesbinarytrades.firebaseapp.com",
  databaseURL: "https://acesbinarytrades-default-rtdb.firebaseio.com",
  projectId: "acesbinarytrades",
  storageBucket: "acesbinarytrades.appspot.com",
  messagingSenderId: "1074612702686",
  appId: "1:1074612702686:web:8e318324be9ee4bdfe214f"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()
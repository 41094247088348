import React from 'react'
import Nav from '../Components/Nav';
import InvestDeposit from '../Components/InvestDeposit';
import Tradingview from '../Components/Tradingview';

function Deposit() {
  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title></title>
      <div className="wrapper">
        <Nav />
        <div id="loader" className="text-center card" style={{ position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855' }}>
          <div className="text-info text-center">
            <img className style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px' }} src="../util/logo/logo-icon.png" height="auto" width="60px" />
          </div>
        </div>
        <div className="main-panel">
          <div className="container">
            <div className="bg-primary2 pt-4 pb-5" style={{ marginBottom: '-80px' }}>
              <div className="container text-white py-2">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h2 className="mt-1">Deposit</h2>
                  </div>
                  <div className="ml-auto">
                    <a href="Withdrawal" className="btn btn-warning btn-round btn-xs">Withdraw</a>
                    <a href="TransferFundsl" className="btn btn-white btn-border btn-round btn-xs">Transfer</a>
                    <a href="/Transactions" className="btn btn-dark btn-round btn-xs">History</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Make Deposit</h4>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <form action method="post">
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-primary text-white">
                                <i className="fa fa-layer-group" />
                              </span>
                            </div>
                            <select name="pack" className="form-control" onchange="get_options_and_hide('package_id',this.value,'#plandsc','#price_tabi')">
                              <option label="Choose Type of Deposit" />
                              <option value={3}>Investment Plans</option>
                            </select>
                          </div>
                        </div>
                        <div id="plandsc" />
                        <div id="dsc" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-head-row card-tools-still-right">
                        <h4 className="card-title">Deposit History</h4>
                        <div className="card-tools">
                          <a href="/DepositHistory" className="btn btn-primary btn-xs">All Deposits</a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div className="table-responsive table-hover table-sales">
                        <table className="table table-striped">
                          <tbody>
                            {/* <tr onclick="clickableRow('transaction.html?open=2894')" className="clickable">
                              <td>
                                <div className="flag">
                                  <img src="https://img.icons8.com/?size=48&id=PjUpgs6o2IFx&format=png" style={{ width: '25px', height: 'auto' }} alt="" />
                                </div>
                              </td>
                              <td><h6 style={{ fontSize: '12px' }} className="fw-bold card-title py-0">Deposit#PAOS2894</h6>
                                <p style={{ fontSize: '8px' }} className="mb-0">06 Oct 2023 11:56:14 pm</p></td>
                              <td className="text-right">
                                <h3 className="fw-medium moni" data-value="$2,000.00">$2,000.00</h3>
                              </td>
                              <td className="text-right text-warning">
                                Pending															</td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  <Tradingview/>
              <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="/Myplans" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
              <InvestDeposit />
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.html">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="Refferals">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2023 made with <a href="home.html">Acesbinarytrade</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
        </a>
        <hr className="m-2" />
        <a href="http://wa.me/newell" id="heheh">
          <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
        </a>
        <a href="http://wa.me/newell">
          <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
      {/* End custom js for this page */}
    </div>
  )
}

export default Deposit
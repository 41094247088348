import React, { useEffect, useRef, useState, useContext } from 'react'
import { GlobalContext } from "../Globalstate";

function Sidebar() {
    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    return (
        <div className="sidebar sidebar-style-2" id="themeBar" data-background-color="white">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <div className="user">
              <div className="avatar-sm float-left mr-2">
                <img src="images/img1.png" alt="..." className="avatar-img rounded-circle" />
              </div>
              <div className="info">
                <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                  <span>
                    {userdetails.fullname} <span className="user-level"> {userdetails.fullname}</span>
                    <span className="caret" />
                  </span>
                </a>
                <div className="clearfix" />
                <div className="collapse in" id="collapseExample">
                  <ul className="nav">
                    <li>
                      <a href="Profile">
                        <span className="link-collapse">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="Refferals">
                        <span className="link-collapse">My Referral</span>
                      </a>
                    </li>
                    <li>
                      <a href="wallet.html">
                        <span className="link-collapse">My Wallet</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ul className="nav nav-primary">
              <li className="nav-item active">
                <a href="#dashboard">
                  <i className="fas fa-home" />
                  <p>Dashboard</p>
                </a>
              </li>
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">Quick Links</h4>
              </li>
              <li className="nav-item">
                <a href="/deposit">
                  <i className="fas fa-upload" />
                  <p>Deposit</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="Withdrawal">
                  <i className="fas fa-download" />
                  <p>Withdraw</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="TransferFundsl">
                  <i className="fas fa-exchange-alt" />
                  <p>Transfer</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="wallet.html">
                  <i className="fas fa-wallet" />
                  <p>My Wallet</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/Myplans">
                  <i className="fas fa-layer-group" />
                  <p>Pricing Plans</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="transaction.html?b=1">
                  <i className="fas fa-gift" />
                  <p>Bonus</p>
                </a>
              </li>
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">History</h4>
              </li>
              <li className="nav-item ">
                <a href="/Transactions">
                  <i className="fas fa-file" />
                  <p>All Transactions</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="/DepositHistory">
                  <i className="fas fa-file" />
                  <p>Deposit History</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="WithdrawalHistory">
                  <i className="fas fa-file" />
                  <p>Withdrawal History</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="TransferHistory">
                  <i className="fas fa-file" />
                  <p>Transfer History</p>
                </a>
              </li>
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="fa fa-ellipsis-h" />
                </span>
                <h4 className="text-section">My Account</h4>
              </li>
              <li className="nav-item ">
                <a href="Profile">
                  <i className="fas fa-user" />
                  <p>My Profile</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="Refferals">
                  <i className="fas fa-users" />
                  <p>My Referral</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="notification.html">
                  <i className="fas fa-bell" />
                  <p>Notifications</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="support.html">
                  <i className="fas fa-comment" />
                  <p>Contact Support</p>
                </a>
              </li>
              <li className="nav-item ">
                <a href="?logout=1">
                  <i className="fas fa-sign-out-alt" />
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
}

export default Sidebar